<template>
  <b-modal
    size="lg"
    id="conferenceModal"
    ref="conferenceModal"
    body-class="cpg-body"
    no-close-on-backdrop
    no-close-on-esc
    hide-footer
  >
    <template slot="modal-header">
      <h5 class="modal-title">Conference Data Import</h5>
      <button type="button" class="close" @click.prevent="closeModal">x</button>
    </template>
    <div class="px-2">
      <v-wait for="loadingSelectedConference">
        <template slot="waiting">
          <content-placeholders :rounded="true" class="pt-4">
            <content-placeholders-text :lines="15"></content-placeholders-text>
          </content-placeholders>
        </template>
        <div>
          <h5><b>How to Import Conference Contacts</b></h5>
          <ol type="1" class="l3-list">
            <li>
              Create a CSV (comma-separated values) file with
              <b>at least</b> the following fields/columns:
              <ul style="list-style-type:disc">
                <li>First Name</li>
                <li>Last Name</li>
                <li>Company</li>
                <li>City</li>
                <li>State</li>
              </ul>
            </li>
            <li>
              File requirements and options:
              <ul style="list-style-type:disc">
                <li>
                  If you need a template, download it here:
                  <a
                    href="\conference_contact_import_template.csv"
                    class="download"
                  >
                    <b>Get Template</b>
                  </a>
                </li>
                <li>A single header row at the top is required.</li>
                <li>
                  Full Name can be used if First Name and Last Name are
                  combined.
                </li>
                <li>
                  Additional fields can be included like title, phone, or ID
                </li>
              </ul>
            </li>

            <li>
              Enter <b>Conference Name</b> and <b>Conference Date</b> below.
            </li>
            <li>Click <b>Choose File</b> and upload the contact csv file.</li>
            <li>
              Map Column Headers in the file to the Required/ Optional fields.
            </li>
            <li>
              Note: Additional fields provided in the file do not need to be
              mapped.
            </li>
            <li>
              Once all the Required/Optional fields are mapped, Click
              <b>Confirm Mapping</b>.
            </li>
            <li>Click <b>Save Conference</b>.</li>
          </ol>
        </div>
        <div class="row">
          <div class="col-sm-12 col-sm-12-mv-70">
            <div class="row justify-content-center">
              <div class="col-sm-12 pb-1">
                <b-form-group>
                  <label for="Search" class="mb-1">
                    <b>Conference Name</b><i class="pl-1">(required on save)</i>
                  </label>
                  <b-form-input
                    type="text"
                    name="conf_name"
                    class="form-control rounded"
                    placeholder="Enter the Conference Name"
                    v-model="conferenceName"
                    ref="conferenceName"
                  ></b-form-input>
                  <b-form-invalid-feedback v-if="!$v.conferenceName.required"
                    >Conference Name can't be blank</b-form-invalid-feedback
                  >
                </b-form-group>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-sm-12">
                <b-form-group>
                  <label for="Search" class="mb-1">
                    <b>Conference Date</b><i class="pl-1">(required on save)</i>
                  </label>

                  <datepicker
                    v-model="conferenceDate"
                    placeholder="Conference Date"
                    format="yyyy-MM-dd"
                    :bootstrap-styling="true"
                    :calendar-button="true"
                    calendar-button-icon="fa fa-calendar"
                    class="start-date-picker "
                    @selected="validateConferenceDate()"
                  ></datepicker>
                  <b-form-invalid-feedback v-if="!$v.conferenceDate.required"
                    >Conference Date can't be blank</b-form-invalid-feedback
                  >
                </b-form-group>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-sm-12">
                <b-form-group>
                  <label for="Search" class="mb-1">
                    <b>Contact file</b><i class="pl-1">(csv)</i>
                  </label>
                  <div class="row">
                    <div class="col-sm-12">
                      <input
                        type="file"
                        accept="text/csv"
                        name="contactFile"
                        id="contactFile"
                        class="inputfile"
                        ref="contactFile"
                        @change="handleFileUpload()"
                      />
                    </div>
                  </div>
                </b-form-group>
              </div>
            </div>
            <div class="modal-content p-2" v-if="validFile">
              <div class="card-header mb-4 pl-1">
                <h5 class="mb-0 mt-1 d-inline-block">Map Fields</h5>
                <button
                  type="button"
                  class="ml-1 pt-1 mt-0 btn btn-secondary rounded float-right"
                  @click="resetMapping"
                >
                  Reset
                </button>
                <button
                  type="button"
                  class="pt-1 mt-0 btn btn-success rounded float-right"
                  @click="freezeMapping"
                >
                  Confirm Mapping
                </button>
              </div>
              <b-form-group class="pl-1 ">
                <b-form-checkbox
                  id="useFullName"
                  name="useFullName"
                  class=" rounded d-inline b-0 pr-2 float-right"
                  placeholder="Use Full Name"
                  v-model="useFullName"
                  ref="useFullName"
                  @change="changeMapping"
                >
                  <span class="col-form-label">Use Full Name</span>
                </b-form-checkbox>
                <!-- <b-form-checkbox
                  id="excludeCityState"
                  name="excludeCityState"
                  class=" rounded d-inline b-0 pr-4 float-right"
                  placeholder="Exclude City and State"
                  v-model="excludeCityState"
                  ref="excludeCityState"
                  @change="excludeMapping"
                >
                  <span class="col-form-label">Exclude City &amp; State </span>
                </b-form-checkbox> -->
              </b-form-group>
              <b-form-group
                label="First Name"
                label-for="user_group"
                :label-cols="3"
                :horizontal="true"
                class="pl-1"
                v-if="!useFullName"
              >
                <multiselect
                  v-model="columnMapping.firstName"
                  label="header"
                  track-by="header"
                  placeholder="Please select a Column Name"
                  :options="options('firstName')"
                  :multiple="false"
                  class="simple-select"
                  :showLabels="false"
                  @select="updateOptions($event, 'firstName')"
                  @remove="removeItem"
                  :disabled="freeze"
                >
                  <template slot="singleLabel" slot-scope="props">
                    <span>
                      {{ props.option.header }}
                    </span>
                  </template>
                  <template slot="option" slot-scope="props">
                    <div class="option__desc">
                      <span class="option__title">
                        <h5>{{ props.option.header }}</h5>
                      </span>
                      <span class="option__small">
                        <b>File Sample: </b>
                        {{ props.option.content }}
                      </span>
                    </div>
                  </template>
                </multiselect>
              </b-form-group>
              <b-form-group
                label="Last Name"
                label-for="user_group"
                :label-cols="3"
                :horizontal="true"
                class="pl-1"
                v-if="!useFullName"
              >
                <multiselect
                  v-model="columnMapping.lastName"
                  label="header"
                  track-by="header"
                  placeholder="Please select a Column Name"
                  :options="options('lastName')"
                  :multiple="false"
                  class="simple-select"
                  :showLabels="false"
                  @select="updateOptions($event, 'lastName')"
                  @remove="removeItem"
                  :disabled="freeze"
                >
                  <template slot="singleLabel" slot-scope="props">
                    <span>
                      {{ props.option.header }}
                    </span>
                  </template>
                  <template slot="option" slot-scope="props">
                    <div class="option__desc">
                      <span class="option__title">
                        <h5>{{ props.option.header }}</h5>
                      </span>
                      <span class="option__small">
                        <b>File Sample: </b>
                        {{ props.option.content }}
                      </span>
                    </div>
                  </template>
                </multiselect>
              </b-form-group>
              <b-form-group
                label="Full Name"
                label-for="user_group"
                :label-cols="3"
                :horizontal="true"
                class="pl-1"
                v-if="useFullName"
              >
                <multiselect
                  v-model="columnMapping.fullName"
                  label="header"
                  track-by="header"
                  placeholder="Please select a Column Name"
                  :options="options('fullName')"
                  :multiple="false"
                  class="simple-select"
                  :showLabels="false"
                  @select="updateOptions($event, 'fullName')"
                  @remove="removeItem"
                  :disabled="freeze"
                >
                  <template slot="singleLabel" slot-scope="props">
                    <span>
                      {{ props.option.header }}
                    </span>
                  </template>
                  <template slot="option" slot-scope="props">
                    <div class="option__desc">
                      <span class="option__title">
                        <h5>{{ props.option.header }}</h5>
                      </span>
                      <span class="option__small">
                        <b>File Sample: </b>
                        {{ props.option.content }}
                      </span>
                    </div>
                  </template>
                </multiselect>
              </b-form-group>
              <b-form-group
                label="Company"
                label-for="user_group"
                :label-cols="3"
                :horizontal="true"
                class="pl-1"
              >
                <multiselect
                  v-model="columnMapping.company"
                  label="header"
                  track-by="header"
                  placeholder="Please select a Column Name"
                  :options="options('company')"
                  :multiple="false"
                  class="simple-select"
                  :showLabels="false"
                  @select="updateOptions($event, 'company')"
                  @remove="removeItem"
                  :disabled="freeze"
                >
                  <template slot="singleLabel" slot-scope="props">
                    <span>
                      {{ props.option.header }}
                    </span>
                  </template>
                  <template slot="option" slot-scope="props">
                    <div class="option__desc">
                      <span class="option__title">
                        <h5>{{ props.option.header }}</h5>
                      </span>
                      <span class="option__small">
                        <b>File Sample: </b>
                        {{ props.option.content }}
                      </span>
                    </div>
                  </template>
                </multiselect>
              </b-form-group>
              <b-form-group
                label="City"
                label-for="user_group"
                :label-cols="3"
                :horizontal="true"
                class="pl-1"
                v-if="!excludeCityState"
              >
                <multiselect
                  v-model="columnMapping.city"
                  label="header"
                  track-by="header"
                  placeholder="Please select a Column Name"
                  :options="options('city')"
                  :multiple="false"
                  class="simple-select"
                  :showLabels="false"
                  @select="updateOptions($event, 'city')"
                  @remove="removeItem"
                  :disabled="freeze"
                >
                  <template slot="singleLabel" slot-scope="props">
                    <span>
                      {{ props.option.header }}
                    </span>
                  </template>
                  <template slot="option" slot-scope="props">
                    <div class="option__desc">
                      <span class="option__title">
                        <h5>{{ props.option.header }}</h5>
                      </span>
                      <span class="option__small">
                        <b>File Sample: </b>
                        {{ props.option.content }}
                      </span>
                    </div>
                  </template>
                </multiselect>
              </b-form-group>
              <b-form-group
                label="State"
                label-for="user_group"
                :label-cols="3"
                :horizontal="true"
                class="pl-1"
                v-if="!excludeCityState"
              >
                <multiselect
                  v-model="columnMapping.state"
                  label="header"
                  track-by="header"
                  placeholder="Please select a Column Name"
                  :options="options('state')"
                  :multiple="false"
                  class="simple-select"
                  :showLabels="false"
                  @select="updateOptions($event, 'state')"
                  @remove="removeItem"
                  :disabled="freeze"
                >
                  <template slot="singleLabel" slot-scope="props">
                    <span>
                      {{ props.option.header }}
                    </span>
                  </template>
                  <template slot="option" slot-scope="props">
                    <div class="option__desc">
                      <span class="option__title">
                        <h5>{{ props.option.header }}</h5>
                      </span>
                      <span class="option__small">
                        <b>File Sample: </b>
                        {{ props.option.content }}
                      </span>
                    </div>
                  </template>
                </multiselect>
              </b-form-group>
              <div
                role="group"
                class="form-row form-group pl-1"
                horizontal="true"
              >
                <span class="col-3 col-form-label">
                  Email <span class="text-muted small"> (Optional)</span>
                </span>
                <div class="bv-no-focus-ring col">
                  <multiselect
                    v-model="columnMapping.email"
                    label="header"
                    track-by="header"
                    placeholder="Please select a Column Name"
                    :options="options('email')"
                    :multiple="false"
                    class="simple-select"
                    :showLabels="false"
                    @select="updateOptions($event, 'email')"
                    @remove="removeItem"
                    :disabled="freeze"
                  >
                    <template slot="singleLabel" slot-scope="props">
                      <span>
                        {{ props.option.header }}
                      </span>
                    </template>
                    <template slot="option" slot-scope="props">
                      <div class="option__desc">
                        <span class="option__title">
                          <h5>{{ props.option.header }}</h5>
                        </span>
                        <span class="option__small">
                          <b>File Sample: </b>
                          {{ props.option.content }}
                        </span>
                      </div>
                    </template>
                  </multiselect>
                </div>
              </div>
              <!-- </b-form-group> -->
              <div
                role="group"
                class="form-row form-group pl-1"
                horizontal="true"
              >
                <span class="col-3 col-form-label">
                  Website <span class="text-muted small"> (Optional)</span>
                </span>
                <div class="bv-no-focus-ring col">
                  <multiselect
                    v-model="columnMapping.website"
                    label="header"
                    track-by="header"
                    placeholder="Please select a Column Name"
                    :options="options('website')"
                    :multiple="false"
                    class="simple-select"
                    :showLabels="false"
                    @select="updateOptions($event, 'website')"
                    @remove="removeItem"
                    :disabled="freeze"
                  >
                    <template slot="singleLabel" slot-scope="props">
                      <span>
                        {{ props.option.header }}
                      </span>
                    </template>
                    <template slot="option" slot-scope="props">
                      <div class="option__desc">
                        <span class="option__title">
                          <h5>{{ props.option.header }}</h5>
                        </span>
                        <span class="option__small">
                          <b>File Sample: </b>
                          {{ props.option.content }}
                        </span>
                      </div>
                    </template>
                  </multiselect>
                </div>
              </div>

              <div
                role="group"
                class="form-row form-group pl-1"
                horizontal="true"
              >
                <span class="col-3 col-form-label">
                  Phone <span class="text-muted small"> (Optional)</span>
                </span>
                <div class="bv-no-focus-ring col">
                  <multiselect
                    v-model="columnMapping.phone"
                    label="header"
                    track-by="header"
                    placeholder="Please select a Column Name"
                    :options="options('phone')"
                    :multiple="false"
                    class="simple-select"
                    :showLabels="false"
                    @select="updateOptions($event, 'phone')"
                    @remove="removeItem"
                    :disabled="freeze"
                  >
                    <template slot="singleLabel" slot-scope="props">
                      <span>
                        {{ props.option.header }}
                      </span>
                    </template>
                    <template slot="option" slot-scope="props">
                      <div class="option__desc">
                        <span class="option__title">
                          <h5>{{ props.option.header }}</h5>
                        </span>
                        <span class="option__small">
                          <b>File Sample: </b>
                          {{ props.option.content }}
                        </span>
                      </div>
                    </template>
                  </multiselect>
                </div>
              </div>
              <div
                role="group"
                class="form-row form-group pl-1"
                horizontal="true"
              >
                <span class="col-3 col-form-label">
                  Title <span class="text-muted small"> (Optional)</span>
                </span>
                <div class="bv-no-focus-ring col">
                  <multiselect
                    v-model="columnMapping.title"
                    label="header"
                    track-by="header"
                    placeholder="Please select a Column Name"
                    :options="options('title')"
                    :multiple="false"
                    class="simple-select"
                    :showLabels="false"
                    @select="updateOptions($event, 'title')"
                    @remove="removeItem"
                    :disabled="freeze"
                  >
                    <template slot="singleLabel" slot-scope="props">
                      <span>
                        {{ props.option.header }}
                      </span>
                    </template>
                    <template slot="option" slot-scope="props">
                      <div class="option__desc">
                        <span class="option__title">
                          <h5>{{ props.option.header }}</h5>
                        </span>
                        <span class="option__small">
                          <b>File Sample: </b>
                          {{ props.option.content }}
                        </span>
                      </div>
                    </template>
                  </multiselect>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h5 class="text-center">
          Provide Conference Name, Conference Date, and Confirm Mapping to Save
          Conference.
        </h5>
        <div class="col-sm-12 d-flex justify-content-center mt-3 mb-3">
          <div class="px-1">
            <button
              type="button"
              class="btn fw-btn btn-secondary rounded"
              @click="closeModal"
            >
              Cancel
            </button>
          </div>
          <div class="px-1 d-flex flex-column">
            <div>
              <button
                type="button"
                :class="['btn fw-btn btn-success rounded']"
                @click="saveConference()"
                :disabled="isDisabled"
              >
                Save Conference
              </button>
            </div>
          </div>
        </div>
        <h5 class="text-center">
          Conference import may take <u>1-3 days</u> to complete.
        </h5>
        <h5 class="text-center">
          You will receive an email when your file is ready.
        </h5>
      </v-wait>
    </div>
  </b-modal>
</template>
<script>
import { required } from 'vuelidate/lib/validators'
import advancedQueryAPI from '@/modules/advanced_query/api/advanced.query'
import Datepicker from 'vuejs-datepicker'
import Multiselect from 'vue-multiselect'
import _ from 'lodash'
export default {
  name: 'ConferenceModal',
  components: {
    Datepicker,
    Multiselect
  },
  computed: {
    isDisabled() {
      return this.$v.$invalid || !this.validFile || this.someFieldsEmpty
    },
    validFormats() {
      return ['text/csv', 'application/vnd.ms-excel']
    }
  },
  data() {
    return {
      loadingSelectedConference: false,
      conferenceName: '',
      conferenceDate: null,
      validFile: false,
      userInputColumns: [],
      firstName: '',
      lastName: '',
      state: '',
      city: '',
      company: '',
      freeze: false,
      columnMapping: {
        firstName: '',
        lastName: '',
        fullName: '',
        state: '',
        city: '',
        company: '',
        email: '',
        website: '',
        phone: '',
        title: ''
      },
      colMap: {},
      someFieldsEmpty: true,
      someMandatoyValuesAreEmpty: false,
      useFullName: false,
      excludeCityState: false
    }
  },
  methods: {
    removeItem(value) {
      if (value) value.skip = false
    },
    options(colName) {
      if (
        this.columnMapping[colName] == '' ||
        this.columnMapping[colName] == null
      )
        return this.userInputColumns.filter(o => !o.skip)
      else
        return [
          this.columnMapping[colName],
          ...this.userInputColumns.filter(o => !o.skip)
        ]
    },
    changeMapping(checked) {
      if (checked) {
        this.updateSkipValue(this.columnMapping.firstName, false)
        this.updateSkipValue(this.columnMapping['lastName'], false)
        this.columnMapping['firstName'] = ''
        this.columnMapping['lastName'] = ''
      } else {
        this.updateSkipValue(this.columnMapping['fullName'], false)
        this.columnMapping['fullName'] = ''
      }
    },

    excludeMapping(checked) {
      if (checked) {
        this.updateSkipValue(this.columnMapping['city'], false)
        this.updateSkipValue(this.columnMapping['state'], false)
        this.columnMapping['city'] = ''
        this.columnMapping['state'] = ''
      }
    },
    updateOptions(selection, fieldName) {
      if (this.columnMapping[fieldName] && this.columnMapping[fieldName] !== '')
        this.updateSkipValue(this.columnMapping[fieldName], false)
      if (selection) {
        this.updateSkipValue(selection, true)
      }
    },
    updateSkipValue(selection, value) {
      if (selection) {
        let selectedItem = this.userInputColumns.find(
          o => o.header === selection.header
        )
        if (selectedItem) selectedItem.skip = value
      }
    },
    validateConferenceDate() {},
    hasDuplicates(array) {
      return new Set(array).size !== array.length
    },
    handleFileUpload() {
      let chosenFile = this.$refs.contactFile.files[0]
      if (this.validFormats.includes(chosenFile.type)) {
        this.contactFile = chosenFile
        const reader = new FileReader()
        reader.readAsText(this.contactFile)

        reader.onload = () => {
          let data = reader.result
          let arr = data.split(/\r?\n/)
          let columns = arr[0].split(',')
          columns = columns.filter(e => e.trim().length >= 1)
          if (this.hasDuplicates(columns)) {
            this.$toasted.show(
              'Duplicate header names. Please make sure the header names are unique.',
              {
                icon: 'chain-broken',
                type: 'error'
              }
            )
            this.$refs.contactFile.value = null
            this.contactFile = null
            this.validFile = false
            return
          }
          if (columns.length > 3) {
            //There are min 4 Mandatory fields when using Full Name
            this.userInputColumns = columns.map(element => {
              return { header: element, content: '', skip: false }
            })
            let max = arr.length > 6 ? 6 : arr.length
            for (let i = 1; i < max; i++) {
              columns = arr[i].split(',')
              columns.forEach((val, index) => {
                if (this.userInputColumns[index])
                  this.userInputColumns[index]['content'] =
                    this.userInputColumns[index]['content'] + val + ' , '
              })
            }
            this.validFile = true
          } else {
            this.$toasted.show(
              'Invalid file content. Please make sure the headers are the first row in the file with minimum four columns.',
              {
                icon: 'chain-broken',
                type: 'error'
              }
            )
            this.$refs.contactFile.value = null
            this.contactFile = null
            this.validFile = false
          }
        }
        //reader.readAsDataURL(this.contactFile)
      } else {
        this.$toasted.show('Invalid file format, please choose csv files...', {
          icon: 'chain-broken',
          type: 'error'
        })
        this.$refs.contactFile.value = null
        this.contactFile = null
        this.validFile = false
      }
    },
    resetMapping() {
      this.columnMapping = {
        firstName: '',
        lastName: '',
        fullName: '',
        state: '',
        city: '',
        company: '',
        email: '',
        website: '',
        phone: '',
        title: ''
      }
      this.useFullName = false
      this.excludeCityState = false
      this.userInputColumns.forEach(e => (e.skip = false))
      this.freeze = false
      this.colMap = {}
      this.someFieldsEmpty = true
    },
    freezeMapping() {
      this.someFieldsEmpty = false
      Object.keys(this.columnMapping).forEach(key => {
        if (this.columnMapping[key])
          this.colMap[key] = this.columnMapping[key]['header']
        else {
          if (
            (this.useFullName && ['firstName', 'lastName'].includes(key)) ||
            (!this.useFullName && ['fullName'].includes(key)) ||
            (this.excludeCityState && ['city', 'state'].includes(key)) ||
            ['email', 'website', 'phone', 'title'].includes(key)
          ) {
            //do nothing
          } else this.someFieldsEmpty = true
        }
      })
      if (this.someFieldsEmpty) {
        this.$toasted.show(
          'Please map columns for all the mandatory fields. Note: The headers should be in the first row of the file.',
          {
            icon: 'chain-broken',
            type: 'error'
          }
        )
        return
      } else this.freeze = true
      //this.validateMandatoryRows()
    },
    validateMandatoryRows() {
      const reader = new FileReader()
      reader.readAsText(this.contactFile)

      reader.onload = () => {
        let mandatoryColIndexes = []
        let headerNames = Object.values(this.colMap)
        let data = reader.result
        let arr = data.split(/\r?\n/)
        let columns = arr[0].split(',')
        columns.forEach((element, index) => {
          if (headerNames.includes(element)) mandatoryColIndexes.push(index)
        })
        for (let i = 1; i < arr.length; i++) {
          columns = arr[i].split(',')
          columns.forEach((val, index) => {
            if (mandatoryColIndexes.includes(index)) {
              if (val.trim().length < 1) this.someMandatoyValuesAreEmpty = true
            }
          })
          if (this.someMandatoyValuesAreEmpty) {
            break
          }
        }
        if (this.someMandatoyValuesAreEmpty) {
          this.$toasted.show(
            'Some manadatory field values are empty. Please update the file with needed information and re-upload.',
            {
              icon: 'chain-broken',
              type: 'error'
            }
          )
          this.$refs.contactFile.value = null
          this.columnMapping = {
            firstName: '',
            lastName: '',
            fullName: '',
            state: '',
            city: '',
            company: '',
            email: '',
            website: '',
            phone: '',
            title: ''
          }
          this.contactFile = null
          this.validFile = false
          this.freeze = false
          return
        }
      }
    },
    closeModal() {
      this.resetData()
      this.$emit('closeModal')
    },
    resetData() {
      this.conferenceName = null
      this.conferenceDate = null
      this.contactFile = null
      this.validFile = false
      this.someFieldsEmpty = true
      this.resetMapping()
    },
    saveConference() {
      if (!this.validFile) {
        this.$toasted.show('Please add a valid file.', {
          icon: 'chain-broken',
          type: 'error'
        })
        return
      }
      if (_.isEmpty(this.colMap)) {
        this.$toasted.show('Please map the fields and confirm mapping', {
          icon: 'chain-broken',
          type: 'error'
        })
        return
      }
      let formData = new FormData()
      formData.append('conference[name]', this.conferenceName)
      formData.append('conference[conference_date]', this.conferenceDate)
      formData.append('conference[file]', this.contactFile)
      formData.append('conference[column_mapping]', JSON.stringify(this.colMap))
      advancedQueryAPI.saveConference(formData).then(res => {
        if (res) {
          this.$toasted.show('Conference successfully added.', {
            icon: 'user-circle',
            type: 'success'
          })
          this.resetData()
          this.$emit('closeModal')
        }
      })
    }
  },
  validations: {
    conferenceName: {
      required
    },
    conferenceDate: {
      required
    }
  }
}
</script>
