<template>
  <div>
    <b-row>
      <b-col sm="12">
        <v-wait for="fetchingConferences">
          <template slot="waiting">
            <content-placeholders :rounded="true">
              <content-placeholders-text
                :lines="20"
              ></content-placeholders-text>
            </content-placeholders>
          </template>
          <div class="row">
            <div class="col-sm-3 offset-sm-6 mb-3 text-right">
              <b-button size="" variant="primary" @click="createConference"
                >Add Conference</b-button
              >
            </div>
            <div class="col-sm-3 mb-3">
              <b-input-group>
                <div class="input-group-prepend">
                  <button type="button" class="btn btn-secondary">
                    <i class="fa fa-search"></i>
                  </button>
                </div>
                <b-form-input
                  type="text"
                  v-model="filter"
                  placeholder="Search"
                  ref="search"
                ></b-form-input>
              </b-input-group>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12"></div>
          </div>

          <b-table
            :items="conferences"
            :fields="fields"
            :show-empty="true"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            @filtered="onFiltered"
            empty-text="No records found."
            responsive="md"
            striped
            hover
          >
            <template v-slot:cell(actions)="data">
              <b-button
                size
                variant="link"
                :href="data.item.url"
                class="pt-0"
                target="_blank"
                ref="download2"
                :disabled="data.item.status !== 'completed'"
              >
                Download
              </b-button>

              <a
                href="#"
                @click="deleteConference(data.item.id)"
                class="pl-2 btn  btn-link pt-0"
              >
                Delete
              </a>
            </template>
          </b-table>
          <b-row>
            <b-col md="6">
              <b-pagination
                :total-rows="totalRows"
                :per-page="perPage"
                v-model="currentPage"
              >
              </b-pagination>
            </b-col>
          </b-row>
        </v-wait>
      </b-col>
    </b-row>
    <ConferenceModal @closeModal="closeModal"></ConferenceModal>
  </div>
</template>
<script>
import ConferenceModal from '@/modules/advanced_query/components/ConferenceModal.vue'
import advancedQueryAPI from '@/modules/advanced_query/api/advanced.query'
//import { mapGetters } from 'vuex'
export default {
  name: 'AQTools',
  components: { ConferenceModal },

  data() {
    return {
      fetchingConferences: false,
      perPage: 20,
      totalRows: 1,
      filter: null,
      currentPage: 1,
      conferences: [],
      selectedConference: {},
      fields: [
        { key: 'name', label: 'Conference  Name', sortable: true },
        { key: 'conference_date', label: 'Conference Date', sortable: true },
        { key: 'created_at', label: 'Upload Date', sortable: true },
        { key: 'status', label: 'Status', sortable: true },
        { key: 'actions', label: 'Actions', sortable: false }
      ]
    }
  },
  created() {
    this.fetchConferences()
  },

  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    createConference() {
      this.$bvModal.show('conferenceModal')
    },
    closeModal() {
      this.$bvModal.hide('conferenceModal')
      this.fetchConferences()
    },
    fetchConferences() {
      this.$wait.start('fetchingConferences')
      advancedQueryAPI.fetchConferences().then(res => {
        if (res) {
          this.conferences = res
          this.totalRows = this.conferences.length
        }
        this.$wait.end('fetchingConferences')
      })
    },
    deleteConference(id) {
      advancedQueryAPI.deleteConference(id).then(() => this.fetchConferences())
    }
  }
}
</script>
